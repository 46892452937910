import './App.css';

function App()
{
  const crowellRankTh = '6th'
  const porterRankTh = '2nd'
  const eckelRankTh = '1st'

  return (
    <div className="App">
      <nav class="navbar navbar-expand-lg fixed-top navbar-shrink" id="mainNav">
        <div class="container">
          <a class="js-scroll-trigger logotext" href="#page-top">
            <img src="/images/head/logo.png" style={{ width: "300px" }} alt="Aikido - Knxoville Aikikai" title="Aikido - Knxoville Aikikai" />
          </a>
          <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars"></i>
          </button>
          <div class="collapse navbar-collapse clearfix" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger text-uppercase" href="#about">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger text-uppercase" href="#lineage">Lineage</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger text-uppercase" href="#instructors">Instructors</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger text-uppercase" href="#schedule">Schedule</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger text-uppercase" href="#pricing">Pricing</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger text-uppercase" href="#links">Links</a>
              </li>
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger text-uppercase" href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <header class="masthead">
        <div class="container d-flex h-100 align-items-center">
          <div class="mx-auto text-center">
            <h2 class="text-white-90 mx-auto mt-3 mb-5" style={{ lineHeight: "2em" }}>"I want considerate people to listen to the voice of Aikido. It is not for correcting others; it is for correcting your own mind.” &ndash;Morihei Ueshiba</h2>
            <img src="/images/head/agatsu.png" alt="Agatsu script" style={{ filter: "alpha(opacity=20)", opacity: "0.5" }} />
          </div>
        </div>
      </header>

      <section id="about" class="section about-section text-center">
        <div class="container light-text">
          <div class="row">
            <div class="col-lg-8 mx-auto">
              <img src="/images/bokken.png" alt="Bokken" class="img-fluid mb-5" style={{ maxWidth: "80%", opacity: "0.5" }} />
              <h2 class="text-white-75 mb-4">About Knoxville Aikikai</h2>
              <p>About Knoxville Aikikai The Knoxville Aikikai was founded in the spring of 1993. We are a United States Aikido Federation member dojo. All students have the opportunity to attend USAF regional seminars hosted and taught by USAF instructors or instructors from Japan. The Chief Instructor of the Knoxville Aikikai is Rob Crowell, {crowellRankTh} dan, Shidoin. Crowell Sensei began Aikido training in 1985 and his early training was under the direction of Akira Tohei Shihan.</p>
              <p>In 1993, Knoxville Aikikai was founded by Rob Crowell Sensei at the direction of Akira Tohei, Shihan. Tohei Shihan was the Chief Instructor of the Midwest Aikido Federation and the Midwest Region of the United States Aikido Federation. Tohei Shihan was born in Tochigi Prefecture, Japan, in 1929, and began the practice of Aikido in 1946 under the direct instruction of Aikido Founder Morihei Ueshiba. In 1966 Tohei Sensei was awarded the title Shihan (Teacher of teachers). We strive to train with the inspiration of Tohei Shihan’s legacy and endeavor to learn from the teachings of the USAF’s current instructors, led by Yoshimitsu Yamada Shihan.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="lineage" class="section lineage-section bg-light">
        <div class="container">
          <div class="row justify-content-center mb-lg-5">
            <h2 class="mb-4">Lineage</h2>
          </div>

          <div class="row align-items-center no-gutters mb-4 mb-lg-5">
            <div class="col-xl-4 col-lg-5">
              <img class="img-fluid mb-3 mb-lg-0 rounded" src="images/lineage/lineage-osensei.jpg" alt="" />
            </div>
            <div class="col-xl-8 col-lg-7">
              <div class="featured-text text-center text-lg-left">
                <h4>Morihei Ueshiba O-Sensei</h4>
                <p class="text-black-75">
                  Morihei Ueshiba, now called O-Sensei (Great Teacher), founded the martial art known today as Aikido. Born in 1883 in Wakayama Prefecture, Japan, he dedicated himself to becoming strong after seeing his father assaulted by political opponents. He sought out and studied under various masters of many traditional martial arts, eventually becoming expert at a number of styles of jujitsu (unarmed combat), kenjutsu (sword fighting), and sojitsu (spear fighting). Dissatisfied with mere strength and technical mastery, he also immersed himself in religious and philosophical studies. The stories of his immense physical strength and martial prowess are impressive enough, but more important is the legacy of nonviolence and human integrity he left to mankind. In early 20th century Japan, involvement in the martial arts was a competitive and dangerous business. Contests, feuds and rivalries often resulted in injuries and even deaths. The formulation of Aikido dates from an incident that occurred in 1925. During a discussion about martial arts, a disagreement arose between O-Sensei and a naval officer who was a fencing instructor. The officer challenged O-Sensei to a match, and attacked with a wooden sword. O-Sensei faced the officer unarmed, and won the match by evading blows until his attacker dropped from exhaustion.
                </p>
                <p class="text-black-75">
                  He later recalled that he could see the opponent’s moves before they were executed, and that was the beginning of his enlightenment. He had defeated an armed attacker without hurting him—without even touching him. O-Sensei later wrote: “Budo (the Martial Way) is not felling the opponent by our force; nor is it a tool to lead the world into destruction with arms. True Budo is to accept the spirit of the universe, keep the peace of the world, correctly produce, protect and cultivate all things in nature”. O-Sensei continued to practice and teach Aikido into his old age. Observers would marvel at his martial abilities, vitality and good humor; he was still giving public demonstrations of Aikido at age 86, four months before his death. After he passed away on April 26, 1969, the Japanese government posthumously declared Morihei Ueshiba a Sacred National Treasure of Japan.
                </p>
              </div>
            </div>
          </div>

          <div class="row align-items-center no-gutters mb-4 mb-lg-5">
            <div class="col-md-6">
              <div class="featured-text text-center text-lg-left">
                <h4>Akira Tohei Sensei</h4>
                <p class="text-black-75 mb-0">
                  Tohei Shihan was the Chief Instructor of the Midwest Aikido Federation and the Midwest Region of the United States Aikido Federation. Tohei Shihan was born in Tochigi Prefecture, Japan, in 1929, and began the practice of Aikido in 1946 under the direct instruction of Aikido Founder Morihei Ueshiba. In 1966 Tohei Sensei was awarded the title Shihan (Teacher of teachers).
                </p>
              </div>
            </div>
            <div class="col-sm-6">
              <img class="img-fluid rounded" src="images/lineage/lineage-tohei-sensei.jpg" style={{ maxWidth: "100%", height: "auto" }} alt="" />
            </div>
          </div>

          <div class="row align-items-center no-gutters mb-4">
            <div class="col-xl-4 col-lg-5">
              <img class="img-fluid mb-3 mb-lg-0 rounded" src="images/lineage/lineage-yamada-sensei.jpg" alt="" />
            </div>
            <div class="col-xl-8 col-lg-7">
              <div class="featured-text text-center text-lg-left">
                <h4>Yoshimitsu Yamada Sensei</h4>
                <p class="text-black-75 mb-0">
                  In 1955, at the age of 18, Yamada Sensei was accepted in the Hombu Dojo uchi-deshi program (live-in apprentice) to study directly with O-Sensei, the founder of Aikido. In addition to O-Sensei, he also studied under Kissomaru Ueshiba, Koichi Tohei and Kisaburo Osawa.  An invitation to demonstrate Aikido at the 1964 World’s Fair brought Yamada Sensei to New York City. He stayed on to become Chief Instructor at the New York Aikikai. By 1972 he already assumed his role as Aikido ambassador to the world at large, introducing his vigorous, inspiring and joyful teaching style to thousands of appreciative students on practically every continent.
                </p>
              </div>
            </div>
          </div>

          <div class="row align-items-center no-gutters">
            <div class="col-xl-8 col-lg-7">
              <div class="featured-text text-center text-lg-left">
                <h4>Rob Crowell Sensei</h4>
                <p class="text-black-75">
                  Rob Crowell began his martial art training in 1973 with Tae Kwon Do. In 1985, Rob began his Aikido training. In 1986, he joined the United States Aikido Federation. Rob's early Aikido career was heavily influenced by Akira Tohei Shihan. Rob was kenshusei (dedicated student) under Tohei Sensei and served as otomo to Tohei Sensei at major seminars and summer camps during the last nine years of Sensei's life.
                </p>
                <p class="text-black-75">
                  Rob has trained under many different Shihan and traveled to Japan twice as part of his training. Rob continues his training by traveling to seminars with Shihan and senior instructors and is dedicated to developing the Aikido training of his students in Knoxville.
                </p>
                <p class="text-black-75">
                  Rob holds the rank of {crowellRankTh} degree black belt awarded by the Aikikai World Headquarters (Hombu Dojo) and the position of Shidoin (certified instructor) granted by the United States Aikido Federation. Rob teaches all of the classes at the Knoxville Aikido Center unless prevented by illness or travel.
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-lg-5">
              <img class="img-fluid rounded" src="images/lineage/lineage-crowell-sensei.jpg" alt="" />
            </div>
          </div>

        </div>
      </section>

      <section id="instructors" class="section instructor-section">
        <div class="container">

          <div class="row justify-content-center mb-lg-5">
            <h2 class="mb-4">Knoxville Dojo Instructors</h2>
          </div>

          <div class="row">
            <div class="col-xs-12 col-md-4 mx-auto text-center">
              <div class="mb-2">
                <img class="rounded instructor-img" alt="Sensei Rob Crowell" src="images/instructors/rob-crowell.jpg" />
              </div>
              <h4 class="mb-0">Rob Crowell</h4>
              <h5 class="mb-2">{crowellRankTh} Dan, Shidoin</h5>
              <p>Chief Instructor Knoxville Aikido Center</p>
              <p>Crowell Sensei started his Aikido training in 1985 and has accumulated 36 years of training with some of the highest ranking Aikidoka from around the globe. He started the Knoxville Aikido Center in 1993 under the direction of Akira Tohei Sensei. With few exceptions Rob leads all classes with personalized instruction for each student.</p>
            </div>
            <div class="col-xs-12 col-md-4 mx-auto text-center">
              <div class="mb-2">
                <img class="rounded instructor-img" alt="Instructor Michael Porter" src="images/instructors/michael-porter.jpg" />
              </div>
              <h4 class="mb-0">Michael Porter</h4>
              <h5 class="mb-2">{porterRankTh} Dan</h5>
              <p>Instructor Knoxville Aikido Center</p>
              <p>Michael started his Aikido training in 2006. His primary tutelage has been under Crowell Sensei with additional training from high ranking Aikidoka from the United States and Japan. Michael leads classes from time to time as directed by the dojo head.</p>
            </div>
            <div class="col-xs-12 col-md-4 mx-auto text-center">
              <div class="mb-2">
                <img class="rounded instructor-img" alt="Instructor Michael Porter" src="images/instructors/nathan-eckel.jpg" />
              </div>
              <h4 class="mb-0">Nathan Eckel</h4>
              <h5 class="mb-2">{eckelRankTh} Dan</h5>
              <p>Instructor Knoxville Aikido Center</p>
              <p>Nathan has been practicing under Crowell Sensei since 2010 and has received instruction from other Shihan throughout his training. Nathan leads classes from time to time as directed by the dojo head.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="schedule" class="section schedule-section">
        <div class="container">

          <div class="row justify-content-center mb-lg-5">
            <h2 class="mb-4 text-white-75">Schedule</h2>
          </div>

          <div class="row">
            <div class="col-md-4 mb-2">
              <div class="card">
                <div class="card-header">
                  Monday
                </div>
                <div class="card-body text-center">
                  <h3 class="card-text">6:30 - 8:00pm</h3>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-2">
              <div class="card">
                <div class="card-header">
                  Wednesday
                </div>
                <div class="card-body text-center">
                  <h3 class="card-text">6:30 - 8:00pm</h3>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-2">
              <div class="card">
                <div class="card-header">
                  Friday
                </div>
                <div class="card-body text-center">
                  <h3 class="card-text">6:30 - 8:00pm</h3>
                </div>
              </div>
            </div>
          </div>

          <section id="pricing" class="section pricing-section">
            <div class="row justify-content-center text-white-75 mb-lg-5 mb-2">
              <h2 class="mb-3">Pricing</h2>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-12 mb-2">
                <div class="jumbotron">
                  <h3>Please enter your name and email address for dojo pricing.</h3>
                  <form action="https://formspree.io/f/xwkgnerp" method="POST">
                    <div class="form-group">
                      <label for="name">Your name:</label>
                      <input name="name" placeholder="Enter name" class="form-control" />
                    </div>
                    <div class="form-group">
                      <label for="email">Your email:</label>
                      <input type="email" name="email" placeholder="Enter email" class="form-control" />
                      <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                    </div>
                    <button type="submit" class="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6 mb-2">
                <div class="card h-100">
                  <div class="card-header">
                    Individual
                  </div>
                  <div class="card-body d-flex flex-column text-center">
                    <h3 class="card-text mt-auto"><b>$65</b><span class="text-muted">/mo.</span></h3>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div class="card h-100">
                  <div class="card-header">
                    Additional Family Members
                  </div>
                  <div class="card-body d-flex flex-column text-center">
                    <h3 class="card-text mt-auto"><b>$32.50</b><span class="text-muted">/mo.</span></h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section id="links" class="section links-section bg-light">
        <div class="container">

          <div class="row justify-content-center mb-lg-5">
            <h2 class="mb-4 text-white-75">Links</h2>
          </div>

          <div class="row">
            <div class="col-md-4 mb-3">
              <div class="card h-100">
                <div class="card-body d-flex flex-column">
                  <h5 class="card-title">United States Aikido Federation</h5>
                  <p class="card-text">The United States Aikido Federation provides both technical and administrative guidance and support to its members, overseeing the practice of Aikido and its instruction as established by Hombu dojo, the international headquarters in Japan.</p>
                  <a target="_blank" rel="noreferrer" href="http://www.usaikifed.com/" class="btn btn-light mt-auto">Go <i class="fas fa-external-link-alt"></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="card h-100">
                <div class="card-body d-flex flex-column">
                  <h5 class="card-title">New York Aikikai</h5>
                  <p class="card-text">The New York Aikikai is home to chief instructor Yoshimitsu Yamada Sensei, 8th Dan, Shihan. He is a direct student of Morihei Ueshiba, the founder of Aikido, and he is the Technical Director of the United States Aikido Federation.</p>
                  <a target="_blank" rel="noreferrer" href="http://nyaikikai.com/" class="btn btn-light mt-auto">Go <i class="fas fa-external-link-alt"></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="card h-100">
                <div class="card-body d-flex flex-column">
                  <h5 class="card-title">Aikido of Scottsdale</h5>
                  <p class="card-text">Aikido of Scottsdale, Arizona led by Glen Brooks Shihan offers traditional Aikido training for adults and children.</p>
                  <a target="_blank" rel="noreferrer" href="http://aikidoofscottsdale.com/" class="btn btn-light mt-auto">Go <i class="fas fa-external-link-alt"></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="card h-100">
                <div class="card-body d-flex flex-column">
                  <h5 class="card-title">Aikido Center of Atlanta</h5>
                  <p class="card-text">
                    The Aikido Center of Atlanta, founded in 1967, is one of the oldest Aikido dojos in the continental United States led by Shihans George Kennedy and Darrell Tangman.
                  </p>
                  <a target="_blank" rel="noreferrer" href="https://www.aikidoatlanta.com/" class="btn btn-light mt-auto">Go <i class="fas fa-external-link-alt"></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="card h-100">
                <div class="card-body d-flex flex-column">
                  <h5 class="card-title">Central Illinois Aikikai</h5>
                  <p class="card-text">
                    Central Illinois Aikikai is a non-profit Aikido school led by Knut Bauer Shidoin. The dojo is located in downtown Urbana, Illinois. Central Illinois Aikikai has offered Aikido instruction in the Urbana, Illinois area since 1974.
                  </p>
                  <a target="_blank" rel="noreferrer" href="https://aikidocia.org/" class="btn btn-light mt-auto">Go <i class="fas fa-external-link-alt"></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="card h-100">
                <div class="card-body d-flex flex-column">
                  <h5 class="card-title">Aikido of Harvard</h5>
                  <p class="card-text">Aikido of Harvard, a Member of the Capital Aikido Federation is led by Terry Leonard, 5th Dan founder and head instructor.</p>
                  <a target="_blank" rel="noreferrer" href="http://www.aikidoharvard.com/" class="btn btn-light mt-auto">Go <i class="fas fa-external-link-alt"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" class="section contact-section bg-black">
        <div class="container">

          <div class="row justify-content-center mb-5">
            <h2 class="text-white-75 mb-4">Contact</h2>
          </div>

          <div class="row justify-content-center mb-4">

            <div class="col-md-4 mb-3 mb-md-0">
              <div class="card py-4 h-100">
                <div class="card-body text-center">
                  <a target="_blank" rel="noreferrer" href="https://duckduckgo.com/?q=522+maryville+pike+knoxville+tn+37920&t=ffsb&atb=v117-7_f&ia=maps&iaxm=maps">
                    <i class="fas fa-map-marked-alt text-primary mb-2"></i>
                    <h4 class="text-uppercase m-0">Address</h4>
                    <hr class="my-4" />
                    <div class="text-black-50">
                      211 W. Fifth Ave<br />
                      Knoxville, TN 37917
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3 mb-md-0">
              <div class="card py-4 h-100">
                <div class="card-body text-center">
                  <div class="fauxLink" onclick="$('.contact-form').toggle()">
                    <i class="fas fa-envelope text-primary mb-2"></i>
                    <h4 class="text-uppercase m-0">Email</h4>
                  </div>
                  <hr class="my-4" />
                  <div class="text-black-50 text-left">
                    <form action="https://formspree.io/f/mjvnebar" method="POST" class="contact-form">
                      <div class="form-group">
                        <label for="name">Name:</label>
                        <input name="name" placeholder="Enter name" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label for="email">Email address:</label>
                        <input type="email" name="email" placeholder="Enter email" class="form-control" />
                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                      </div>
                      <div class="form-group">
                        <label for="message">Message:</label>
                        <textarea name="message" placeholder="Enter message" class="form-control"></textarea>
                      </div>
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3 mb-md-0">
              <div class="card py-4 h-100">
                <div class="card-body text-center">
                  <i class="fas fa-mobile-alt text-primary mb-2"></i>
                  <h4 class="text-uppercase m-0">Phone</h4>
                  <hr class="my-4" />
                  <div class="text-black-50">(865) 548-3303</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center mb-4">
            <div class="col-xs-12">
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/KnoxvilleAikidoCenter/">
                <div class="social-fb text-center"></div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <footer class="bg-black small text-center text-white-50">
        <div class="container">
          Copyright &copy; Knoxville Aikikai 2021
        </div>
      </footer>

    </div>
  );
}

export default App;
